<template>
    <el-card shadow="never" style="margin-top: 8px">
        <el-form :inline="false" label-width="80px" v-if="prepared" size="small">
            <el-checkbox-group
                v-model="temBindCodes"
                @change="checkGroupCode"
                :disabled="!hasPrivilege(checkPrivilegeFlag)"
            >
                <el-row :gutter="12">
                    <el-col :span="12">
                        <el-card shadow="never">
                            <el-form-item label="库存:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.stock.query']"
                                    :disabled="notEditable('biz.stock.query')"
                                    >查看库存数量</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['biz.stock.check']"
                                    :disabled="notEditable('biz.stock.check')"
                                    >盘点</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['biz.stock.lossAndOverflow.autoAdjustStock']"
                                    :disabled="notEditable('biz.stock.lossAndOverflow.autoAdjustStock')"
                                    >一键报损报溢</el-checkbox
                                >
                            </el-form-item>
                            <el-form-item label="供应商:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.bind.supplier.query']"
                                    :disabled="notEditable('biz.bind.supplier.query')"
                                    >查看"绑定供应商"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.bind.supplier.edit']"
                                    :disabled="notEditable('biz.bind.supplier.edit')"
                                    >查看"编辑绑定供应商"
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="价格:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.price.plan.query']"
                                    :disabled="notEditable('biz.price.plan.query')"
                                    >查看"计划成本"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.price.factory.query']"
                                    :disabled="notEditable('biz.price.factory.query')"
                                    >查看"出厂价"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.price.wholeSale.query']"
                                    :disabled="notEditable('biz.price.wholeSale.query')"
                                    >查看"批发价"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.price.retail.query']"
                                    :disabled="notEditable('biz.price.retail.query')"
                                    >查看"零售价"
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['biz.price.plan.edit']"
                                    :disabled="notEditable('biz.price.plan.edit')"
                                    >编辑"计划成本"
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="角色:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.roleMenu.query']"
                                    :disabled="notEditable('biz.roleMenu.query')"
                                    >查看"角色绑定菜单"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.roleMenu.edit']"
                                    :disabled="notEditable('biz.roleMenu.edit')"
                                    >编辑"角色绑定菜单"
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['biz.rolePrivilege.query']"
                                    :disabled="notEditable('biz.rolePrivilege.query')"
                                    >查看"角色绑定权限"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.rolePrivilege.edit']"
                                    :disabled="notEditable('biz.rolePrivilege.edit')"
                                    >编辑"角色绑定权限"
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['biz.roleStaff.query']"
                                    :disabled="notEditable('biz.roleStaff.query')"
                                    >查看"角色绑定员工"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.roleStaff.edit']"
                                    :disabled="notEditable('biz.roleStaff.edit')"
                                    >编辑"角色绑定员工"
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['menu.system.role.deptGroup.menu.show']"
                                    :disabled="notEditable('menu.system.role.deptGroup.menu.show')"
                                    >查询"机构组角色可用菜单"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['menu.system.role.deptGroup.menu.edit']"
                                    :disabled="notEditable('menu.system.role.deptGroup.menu.edit')"
                                    >编辑"机构组角色可用菜单"
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['menu.system.role.deptGroup.privilege.show']"
                                    :disabled="notEditable('menu.system.role.deptGroup.privilege.show')"
                                    >查询"机构组角色可用权限"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['menu.system.role.deptGroup.privilege.edit']"
                                    :disabled="notEditable('menu.system.role.deptGroup.privilege.edit')"
                                    >编辑"机构组角色权限"
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="机构组角色:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.deptGroupRoleMenu.query']"
                                    :disabled="notEditable('biz.deptGroupRoleMenu.query')"
                                    >查看"角色绑定菜单"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.deptGroupRoleMenu.edit']"
                                    :disabled="notEditable('biz.deptGroupRoleMenu.edit')"
                                    >编辑"角色绑定菜单"
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['biz.deptGroupRolePrivilege.query']"
                                    :disabled="notEditable('biz.deptGroupRolePrivilege.query')"
                                    >查看"角色绑定权限"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.deptGroupRolePrivilege.edit']"
                                    :disabled="notEditable('biz.deptGroupRolePrivilege.edit')"
                                    >编辑"角色绑定权限"
                                </el-checkbox>
                            </el-form-item>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card shadow="never">
                            <el-form-item label="员工:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.staff.type.edit']"
                                    :disabled="notEditable('biz.system.staff.type.edit')"
                                    >编辑员工类型
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.staff.leave.dept']"
                                    :disabled="notEditable('biz.system.staff.leave.dept')"
                                    >操作“离职”按钮
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="收银:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.staffCash.show']"
                                    :disabled="notEditable('biz.system.staffCash.show')"
                                    >查看员工最大折扣
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.finance.daily.settle.all']"
                                    :disabled="notEditable('biz.finance.daily.settle.all')"
                                    >对整店收银数据日结
                                </el-checkbox>
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.staffCash.edit']"
                                    :disabled="notEditable('biz.system.staffCash.edit')"
                                    >编辑员工最大折扣
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="审核:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.biz.reject']"
                                    :disabled="notEditable('biz.biz.reject')"
                                    >驳回单据</el-checkbox
                                >
                            </el-form-item>
                            <el-form-item label="采购:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.skuPurchase.head.operate']"
                                    :disabled="notEditable('biz.skuPurchase.head.operate')"
                                    >总部单方采购
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.skuPurchase.repo.operate']"
                                    :disabled="notEditable('biz.skuPurchase.repo.operate')"
                                    >配送中心单方采购
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.skuPurchase.store.operate']"
                                    :disabled="notEditable('biz.skuPurchase.store.operate')"
                                    >机构单方采购
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="机构设置:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.deptPrivilege.query']"
                                    :disabled="notEditable('biz.system.deptPrivilege.query')"
                                    >查看"机构设置"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.deptPrivilege.edit']"
                                    :disabled="notEditable('biz.system.deptPrivilege.edit')"
                                    >编辑"机构设置"
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.deptPrivilege.renewal']"
                                    :disabled="notEditable('biz.system.deptPrivilege.renewal')"
                                    >机构收银系统续费
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.system.closeDeptBizPrivilege.execute']"
                                    :disabled="notEditable('biz.system.closeDeptBizPrivilege.execute')"
                                    >申请退店
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="数据:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.report.datadsp.nation.query']"
                                    :disabled="notEditable('biz.report.datadsp.nation.query')"
                                    >查看全国数据大屏
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.report.datadsp.query']"
                                    :disabled="notEditable('biz.report.datadsp.query')"
                                    >查看数据大屏
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.report.datadsp.month.query']"
                                    :disabled="notEditable('biz.report.datadsp.month.query')"
                                    >查看月度数据大屏
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.report.datadsp.lqxx.query']"
                                    :disabled="notEditable('biz.report.datadsp.lqxx.query')"
                                    >查看朗勤心选数据大屏
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="后台配置:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.back.config.upload']"
                                    :disabled="notEditable('biz.back.config.upload')"
                                    >上传文件
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item label="反馈收集:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.user.feedBack.accept']"
                                    :disabled="notEditable('biz.user.feedBack.accept')"
                                    >受理
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.user.feedBack.close']"
                                    :disabled="notEditable('biz.user.feedBack.close')"
                                    >关闭
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.user.feedBack.online']"
                                    :disabled="notEditable('biz.user.feedBack.online')"
                                    >上线
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.user.feedBack.reaccpet']"
                                    :disabled="notEditable('biz.user.feedBack.reaccpet')"
                                    >重新受理
                                </el-checkbox>
                            </el-form-item>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row :gutter="12">
                    <el-col :span="12">
                        <el-card shadow="never" style="margin-top: 8px">
                            <el-form-item label="收银机:">
                                <el-checkbox
                                    :label="metaPrivileges['pos.cash.create']"
                                    :disabled="notEditable('pos.cash.create')"
                                    >可收银</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['pos.refund.create']"
                                    :disabled="notEditable('pos.refund.create')"
                                    >可退款</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['pos.holdOn.create']"
                                    :disabled="notEditable('pos.holdOn.create')"
                                    >可挂单</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['pos.peopleGift.create']"
                                    :disabled="notEditable('pos.peopleGift.create')"
                                    >赠送商品</el-checkbox
                                >
                                <br />
                                <el-checkbox
                                    :label="metaPrivileges['pos.discount.create']"
                                    :disabled="notEditable('pos.discount.create')"
                                    >可折扣</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['pos.minus.create']"
                                    :disabled="notEditable('pos.minus.create')"
                                    >可议价</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['pos.query.allHandover']"
                                    :disabled="notEditable('pos.query.allHandover')"
                                    >可查看全部交班信息
                                </el-checkbox>
                            </el-form-item>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card shadow="never" style="margin-top: 8px">
                            <el-form-item label="会员:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.member.update.batch.developMember']"
                                    :disabled="notEditable('biz.member.update.batch.developMember')"
                                    >批量修改推荐人
                                </el-checkbox>
                                <el-checkbox
                                    :label="metaPrivileges['biz.member.update.batch.belongDept']"
                                    :disabled="notEditable('biz.member.update.batch.belongDept')"
                                    >批量修改所属机构
                                </el-checkbox>
                            </el-form-item>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row :gutter="12">
                    <el-col :span="12">
                        <el-card shadow="never" style="margin-top: 8px">
                            <el-form-item label="电商管理:">
                                <el-checkbox
                                    :label="metaPrivileges['biz.report.distributionMemberDeptCount.clear']"
                                    :disabled="notEditable('biz.report.distributionMemberDeptCount.clear')"
                                    >清算商分</el-checkbox
                                >
                                <el-checkbox
                                    :label="metaPrivileges['biz.report.distributionMemberDeptCount.clear.log']"
                                    :disabled="notEditable('biz.report.distributionMemberDeptCount.clear.log')"
                                    >查看清算操作记录</el-checkbox
                                >
                            </el-form-item>
                        </el-card>
                    </el-col>
                </el-row>
            </el-checkbox-group>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'BizPrivilegeConfig',
    props: {
        checkPrivilegeFlag: {
            default: '',
        },
        bindCodes: {
            default: [],
        },
        metaPrivileges: {
            default: {},
        },
        prepared: {
            default: false,
        },
    },
    watch: {
        bindCodes: {
            deep: true,
            handler(newVal, oldVal) {
                this.temBindCodes = newVal;
            },
        },
    },
    data() {
        return {
            temBindCodes: this.bindCodes,
        };
    },
    methods: {
        notEditable(metaPrivilege) {
            //查不到直接锁定
            return !this.metaPrivileges[metaPrivilege];
        },
        checkGroupCode() {
            this.$emit('changeBindCodes', this.temBindCodes);
        },
    },
};
</script>
